<template>
    <div>
        <!-- <p class="has-text-centered">{{ yearSelected.name }}</p> -->

        <br>

<!--        <button class="button" :class="{ 'year-active': y.name === yearSelected.name}" v-for="y in yearOptions" :key="y.id" @click="yearSelected = y">-->
<!--            {{ y.name }}-->
<!--        </button>-->

<!--        <hr>-->
      <div v-for="pw in previousPolicies" :key="pw.name">

        <p class="title is-5 my-4">{{ pw.text }}</p>

        <div class="box pw-box background-c2g-blue has-text-white my-1">
          <div class="columns">
            <div class="column is-1">
              <b-icon pack="fa" icon="file-pdf" size="is-large" type="is-primary"/>
            </div>
            <div class="column">
              <p>Single Trip</p>
              <hr class="sep">
              <a :href="$c2g.getPolicyWordings('PLATINUM', false, false, pw.value, 'ST')" target="_blank">Platinum Policy Wording</a> |
              <a :href="$c2g.getPolicyWordings('GOLD', false, false, pw.value, 'ST')" target="_blank">Gold Policy Wording</a> |
              <a :href="$c2g.getPolicyWordings('SILVER', false, false, pw.value, 'ST')" target="_blank">Silver Policy Wording</a>
            </div>
          </div>
        </div>

        <div class="box pw-box background-c2g-blue has-text-white my-1">
          <div class="columns">
            <div class="column is-1">
              <b-icon pack="fa" icon="file-pdf" size="is-large" type="is-primary"/>
            </div>
            <div class="column">
              <p>Annual Multi-Trip</p>
              <hr class="sep">
              <a :href="$c2g.getPolicyWordings('PLATINUM', false, false, pw.value, 'AT')" target="_blank">Platinum Policy Wording</a> |
              <a :href="$c2g.getPolicyWordings('GOLD', false, false, pw.value, 'AT')" target="_blank">Gold Policy Wording</a> |
              <a :href="$c2g.getPolicyWordings('SILVER', false, false, pw.value, 'AT')" target="_blank">Silver Policy Wording</a>
            </div>
          </div>
        </div>

        <div class="box pw-box background-c2g-blue has-text-white my-1" v-if="pw.hasCruise">
          <div class="columns">
            <div class="column is-1">
              <b-icon pack="fa" icon="file-pdf" size="is-large" type="is-primary"/>
            </div>
            <div class="column">
              <p>Cruise</p>
              <hr class="sep">
              <a :href="$c2g.getPolicyWordings('', false, true, pw.value)" target="_blank">Cruise Policy Wording</a>
            </div>
          </div>
        </div>

        <div class="box pw-box background-c2g-blue has-text-white my-1">
          <div class="columns">
            <div class="column is-1">
              <b-icon pack="fa" icon="file-pdf" size="is-large" type="is-primary"/>
            </div>
            <div class="column">
              <p>Longstay</p>
              <hr class="sep">
              <a :href="$c2g.getPolicyWordings('LONGSTAY', false, false, pw.value, 'LS')" target="_blank">Longstay Policy Wording</a>
            </div>
          </div>
        </div>
      </div>
        <div v-for="pw in currentPolicies" :key="pw.name">

            <p class="title is-5 my-4">{{ pw.text }}</p>

            <div class="box pw-box background-c2g-blue has-text-white my-1">
                <div class="columns">
                    <div class="column is-1">
                        <b-icon pack="fa" icon="file-pdf" size="is-large" type="is-primary"/>
                    </div>
                    <div class="column">
                        <p>Single Trip and Annual Multi-Trip</p>
                        <hr class="sep">
                        <a :href="$c2g.getPolicyWordings('PLATINUM', false, false, pw.value, )" target="_blank">Platinum Policy Wording</a> |
                        <a :href="$c2g.getPolicyWordings('GOLD', false, false, pw.value,)" target="_blank">Gold Policy Wording</a> |
                        <a :href="$c2g.getPolicyWordings('SILVER', false, false, pw.value, )" target="_blank">Silver Policy Wording</a>
                    </div>
                </div>
            </div>

            <div class="box pw-box background-c2g-blue has-text-white my-1">
                <div class="columns">
                    <div class="column is-1">
                        <b-icon pack="fa" icon="file-pdf" size="is-large" type="is-primary"/>
                    </div>
                    <div class="column">
                        <p>Winter Sports - Single Trip and Annual Multi-Trip</p>
                        <hr class="sep">
                        <a :href="$c2g.getPolicyWordings('PLATINUM', true, false, pw.value)" target="_blank">Platinum Policy Wording</a> |
                        <a :href="$c2g.getPolicyWordings('GOLD', true, false, pw.value)" target="_blank">Gold Policy Wording</a> |
                        <a :href="$c2g.getPolicyWordings('SILVER', true, false, pw.value)" target="_blank">Silver Policy Wording</a>
                    </div>
                </div>
            </div>

            <div class="box pw-box background-c2g-blue has-text-white my-1">
                <div class="columns">
                    <div class="column is-1">
                        <b-icon pack="fa" icon="file-pdf" size="is-large" type="is-primary"/>
                    </div>
                    <div class="column">
                        <p>Cruise - Single Trip and Annual Multi-Trip</p>
                        <hr class="sep">
                        <a :href="$c2g.getPolicyWordings('', false, true, pw.value)" target="_blank">Cruise Policy Wording</a> |
                        <a :href="$c2g.getPolicyWordings('', true, true, pw.value)" target="_blank">Cruise with Winter Sports Policy Wording</a>
                    </div>
                </div>
            </div>

            <div class="box pw-box background-c2g-blue has-text-white my-1">
                <div class="columns">
                    <div class="column is-1">
                        <b-icon pack="fa" icon="file-pdf" size="is-large" type="is-primary"/>
                    </div>
                    <div class="column">
                        <p>Longstay</p>
                        <hr class="sep">
                        <a :href="$c2g.getPolicyWordings('LONGSTAY', false, false, pw.value, 'LS')" target="_blank">Longstay Policy Wording</a>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { contentAPI } from "@/mixins/content-api";
    import { page } from "@/mixins/page";

    export default {
        name: "PolicyWording",
        data() {
            return {
                yearOptions: [
                    { name: 'For Policies Purchased from 16:00 23/12/2022 to 23:59 20/06/2023', options: [10] },
                    { name: 'Older', options: [1, 2, 5, 3, 4, 6, 7] }
                ],
                policyWordings: [


                    { name: '07-07-2021 to 12-07-2021', text: 'For Policies Purchased from 17:00 07/07/2021 to 12/07/2021', value: 7 },
                    { name: '01-06-2021 to 07-07-2021', text: 'For Policies Purchased from 00:01 01/06/2021 to 16:59 07/07/2021', value: 6 },
                    { name: '28-08-2020 to 31-05-2021', text: 'For Policies Purchased from 29/08/2020 to 31/05/2021.', value: 1 },
                    { name: '01-06-2020 to 27-08-2020', text: 'For Policies Purchased from 01/06/2020 to 27/08/2020.', value: 2 },
                    { name: '17-03-2020 to 31-05-2020', text: 'For Policies Purchased from 17/03/2020 to 31/05/2020.', value: 5 },
                    { name: '01-06-2019 to 31-05-2020', text: 'For Policies Purchased from 01/06/2019 to 31/05/2020.', value: 3 },
                    { name: '01-06-2018 to 31-05-2019', text: 'For Policies Purchased from 01/06/2018 to 31/05/2019.', value: 4 }
                ],
                newFormatPolicyWordings : [
                  { name: '00:00 08/05/2024 to 23:59 28/11/2024', text: 'For Policies Purchased from 00:00 08/05/2024 to 23:59 28/11/2024', value: 13, hasCruise: false },
                  { name: '00:00 08/02/2024 to 23:59 07/05/2024', text: 'For Policies Purchased from 00:00 08/02/2024 to 23:59 07/05/2024', value: 12, hasCruise: false },
                  { name: '00:00 21/06/2023 to 23:59 07/02/2024', text: 'For Policies Purchased from 00:00 21/06/2023 to 23:59 07/02/2024', value: 11, hasCruise: true },
                  { name: '16:00 23/12/2022 to 23:59 20/06/2023', text: 'For Policies Purchased from 16:00 23/12/2022 to 23:59 20/06/2023', value: 10, hasCruise: true },
                  { name: '01/06/2022 to 15:59 23/12/2022', text: 'For Policies Purchased from 00:00 01/06/2022 to 15:59 23/12/2022', value: 9 , hasCruise: true},
                  { name: '11:00 14/10/2021 to 23:59 31/05/2022', text: 'For Policies Purchased from 11:00 14/10/2021 to 23:59 31/05/2022', value: 8, hasCruise: true }],
                yearSelected: null
            }
        },
        computed: {
            currentPolicies() {
              return this.policyWordings;
                // return this.policyWordings.filter(pw => {
                //     return this.yearSelected.options.includes(pw.value);
                // });
            },
            previousPolicies() {
              return this.newFormatPolicyWordings;
            }
        },
        mixins: [ contentAPI, page ],
        created() {
            this.yearSelected = this.yearOptions[0];
        }
    }
</script>

<style lang="scss" scoped>
    .pw-box {
        border-bottom: 3px $c2g_orange solid;
    }
    .sep {
        margin-top: 5px;
        margin-bottom: 5px;
    }
    .year-active {
        background-color: $c2g_orange;
        border-color: $c2g_orange;
        color: white;
    }
    button {
        margin-right: 5px;
    }
</style>